<template>
  <div class="col-lg-7 mb-5">
    <Agile :options="myOptions" class="owl-carousel owl-theme landing-page-slider theme-slider ltr" v-if="sortDealData.length>0">
      <div class="slide" v-for="(slide, index) in sortDealData" :key="index">

        <b-img-lazy
          v-bind="mainProps"
          class="background-image"
          :src="(slide.categoryId === 'Organize_tour_packages' || slide.categoryId === 'Flight_Only') ? slide.destImageUrls[Math.floor(slide.destImageUrls.length*Math.random())] || slide.imageUrl || getDestinationImage(slide.destinationCode) || `${imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`: slide.perspectiveUrl[Math.floor(slide.perspectiveUrl.length*Math.random())] || slide.imageUrl || slide.destImageUrls[0] || getDestinationImage(slide.destinationCode) || '${imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg'"
          alt="Not Found"
          :onerror="`this.src='${imageDomain}/assets/img/lesiurpackages_hotelsimg4.jpg'`"
          width="500" height="400"
        ></b-img-lazy>

          <div class="caption" >
            <div class="con" dir="rtl">
              <h4 v-if="slide.categoryId === 'Flight_Only'">
                תל אביב - {{slide.destinationName['he']}} ,{{slide.destinationCountryName['he']}} - תל אביב
              </h4>
              <h4 v-else>
                {{slide.destinationName['he']}}, {{slide.destinationCountryName['he']}} - {{slide.hotelName['he']}}
              </h4>
                <ul >
                  <li><i class="fas fa-plane fa-rotate-180"></i> <strong >יציאה:</strong> <i class="far fa-calendar-alt"></i>
                    {{ changeDateFomart(slide.roundTripFlight ? slide.roundTripFlight.FlightDetail[0].FL_Date: '')}}
                  </li>
                  <li><i class="fas fa-plane"></i> <strong >חזרה:</strong> <i class="far fa-calendar-alt"></i>
                    {{ changeDateFomart(slide.roundTripFlight ? slide.roundTripFlight.FlightDetail[1].FL_Date: '')}}
                  </li>
                  <li v-if="slide.packageType !== 'F'"><img :src="`${imageDomain}/assets/img/flight4.png`" alt="flight4" width="15" height="15"> <strong >בסיס אירוח:</strong> {{ slide.boardBasisName['he'] }}</li>
                </ul>
              <h3>${{ (slide.discountedPrice) ? slide.discountedPrice.price_average : slide.discountedPriceFO.priceByAge[0].price }} <small>{{ slide.priceRemark }}</small></h3>
              <button class="btn btn-primary" :class="`${landingInfo.utm_source} - ${destinationName.he}`">
                <a style="color:white;" :href="`${slide.selectedPackageUrl}&roomClass=${slide.roomClass}`">
                  להזמנה לחץ כאן <i class="fas fa-caret-left"></i></a>
              </button>
            </div>
          </div>
      </div>

      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>

    </Agile>
  </div>
</template>

<script>
import { BImgLazy } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'LandingBannerSlide',
  mixins: [gMixin, imageUrlMixin],
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
    BImgLazy,
  },
  props: {
    dealTypeCode: String,
    areaDest: String,
  },
  computed: {
    ...mapGetters({
      landingDealData: 'GET_LANDING_DEAL_DATA',
      landingInfo: 'GET_LANDING_INFO',
      destinationImages: 'GET_DESTINATION_IMAGES',
    }),
    isAreaType() {
      const { landingInfo } = this;
      if (!landingInfo) return false;
      return landingInfo.areaType || false;
    },
    sortDealData() {
      const { landingDealData, dealTypeCode } = this;
      if (!landingDealData.length) return [];

      const filteredDeals = landingDealData.filter((item) => item.dealTypeCode === dealTypeCode);
      let tmpDeals = (dealTypeCode === '' || filteredDeals.length < 4) ? [...landingDealData] : filteredDeals;
      tmpDeals.sort((a, b) => {
        const priceA = (a.discountedPrice) ? a.discountedPrice.price_average : a.discountedPriceFO.priceByAge[0].price,
          priceB = (b.discountedPrice) ? b.discountedPrice.price_average : b.discountedPriceFO.priceByAge[0].price;
        return priceA - priceB;
      });
      if (dealTypeCode === 'Flight_Only' || dealTypeCode === 'Organize_tour_packages') {
        tmpDeals = Object.entries(this.groupByKey(tmpDeals, 'id'));
      } else {
        tmpDeals = Object.entries(this.groupByKey(tmpDeals, 'hotelId'));
      }
      tmpDeals.sort((a, b) => {
        const priceA = (a[1][0].discountedPrice) ? a[1][0].discountedPrice.price_average : a[1][0].discountedPriceFO.priceByAge[0].price,
          priceB = (b[1][0].discountedPrice) ? b[1][0].discountedPrice.price_average : b[1][0].discountedPriceFO.priceByAge[0].price;
        return priceA - priceB;
      });
      const sortDealData = tmpDeals.slice(0, 4).map((one) => one[1][0]);
      return sortDealData;
    },
  },
  watch: {
    areaDest() {
      // console.log(this.areaDest);
    },
  },
  data() {
    return {
      slide: 0,
      myOptions: {
        autoplay: true,
        infinite: true,
        navButtons: true,
        autoplaySpeed: 5000,
        dots: true,
        speed: 2000,
        fade: true,
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      destinationName: '',
    };
  },
  mounted() {
    if (this.landingDealData && this.landingDealData.length > 0) {
      this.destinationName = this.landingDealData[0].destinationName;
    }
  },
  methods: {
    changeDateFomart(str) {
      return str.split('-').reverse().join('/');
    },
    getDestinationImage(dest) {
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      let url = (matchedItem) ? this.destinationImages.find((d) => d.code === dest).imageUrls[0] : `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg3.jpg`;
      if (!url) url = `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg3.jpg`;
      return url;
    },
  },
};
</script>

<style scoped>
.landing-page-slider .slide {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.landing-page-slider .slide img.background-image {
    display: flex;
    min-height: 500px;
    max-height: 500px;
    object-fit: cover;
}
.landing-page-slider .slide .caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 100%;
    text-align: left;
    z-index: 99;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.landing-page-slider .slide .con {
    max-width: 100%;
    margin: auto;
    max-width: 460px;
    width: 100%;
    margin: auto;
    text-align: center;
    background-color: rgb(0 0 0 / 37%);
    border: 5px solid rgb(255 255 255 / 33%);
    color: #fff;
    box-sizing: border-box;
    padding: 30px;
}
.landing-page-slider .slide .con {
    z-index: 5;
    position: relative;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.landing-page-slider .slide.agile__slide--active .con {
  transform: translate3d(0,0,0);
  opacity: 1;
  transition: opacity 0.4s ease, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.landing-page-slider .slide.agile__slide--active .con>* {
  -webkit-animation: fade-down 1.5s ease both;
  animation: fade-down 1.5s ease both;
}

.landing-page-slider .slide .con h4 {
    font-size: 1.5em;
    margin-bottom: 6px;
    text-transform: capitalize;
    font-weight: bold;
}
.landing-page-slider .slide .con ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.landing-page-slider .slide .con ul li {
    display: block;
    border: 1px solid rgb(255 255 255 / 36%);
    margin-top: 2px;
    box-sizing: border-box;
    padding: 5px 5px;
    font-size: 1.2rem;
    background-color: rgb(0 0 0 / 48%);
    max-width: 390px;
    margin: auto;
    margin-top: -1px;
}
.landing-page-slider .slide .con ul li strong {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 1em;
    margin-right: 5px;
}
.landing-page-slider .slide .con h3 {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 5px;
    letter-spacing: 0.5px;
}
.landing-page-slider .slide .con .btn-primary {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.landing-page-slider .slide .con ul li img {
    max-width: 20px;
    width: 100%;
    height: auto;
    display: inline-block;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 7px;
    margin-left: 5px;
}
</style>
<style>
.landing-page-slider .agile__list{
  box-shadow: 0px 0px 2px 9px rgb(0 97 171 / 15%);
}
.landing-page-slider .agile__actions .agile__dots {
  position: absolute;
  bottom: 20px;
}
.landing-page-slider .agile__nav-button{
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  opacity: 0.5;
  transform: scaleY(1.5);
}
.landing-page-slider .agile__nav-button--prev {
  left: 35px;
  right: auto;
}
.landing-page-slider .agile__nav-button--next {
  right: 35px;
  left: auto;
}
.landing-page-slider .agile__nav-button:hover {
  box-shadow: none;
    opacity: 0.7;
}
@media (max-width: 768px){
  .landing-page-slider.owl-carousel.theme-slider {
    padding: 0;
    width: calc(100% - 15px);
    margin: auto;
  }
  .landing-page-slider .agile__nav-button--prev {
    left: -5px;
    right: auto;
  }
  .landing-page-slider .agile__nav-button--next {
    right: -5px;
    left: auto;
  }
}
</style>
